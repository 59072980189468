<template>
  <div class="pay-succeed">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">
        {{ courseName }}
      </div>
      <div class="right"></div>
    </div>
    <div class="main">
      <div class="title">
        支付成功
        <span class="price"> <span class="yuan">¥</span> {{ price }}</span>
      </div>
      <div class="info-1">建议【截图】保存本页面 <br /></div>
      <div class="info-learn-center">
        【视频链接】<br />
        <span class="highlight">公众号：凯威老师在讲课</span> 菜单栏 <br />
        <div class="next-step-or">或者 【网页浏览器】 输入</div>
        <span class="highlight">aida101.com</span> <br />
        <span class="highlight">“我的课程”</span>查看已购课程 <br />
        <br />
        <span class="highlight">讲义和群二维码在第一节课</span>
      </div>

      <div class="next-step">
        <button class="btn" @click="onGotoMyCourse">去学习 {{ jumpSeconds }}</button>
      </div>

      <!-- <div class="phone-and-date">{{ userPhone }} , {{ payTime }}</div> -->

      <!-- <div class="info-cs">如有问题，下方二维码可找客服</div>
      <div class="img">
        <img :src="contactImg" alt="" />
      </div> -->
    </div>

    <div class="footer">
      <footerMenu></footerMenu>
    </div>

    <csWidget v-if="showCsWidget" :csId="'1'"></csWidget>
  </div>
</template>

<script>
import { TouchEmulator } from "@/util/touch.js";
import csWidget from "@/components/csWidget.vue";
import footerMenu from "@/components/footerMenu.vue";
import { payApi } from "@/api/payApi.js";
import { orderApi } from "@/api/orderApi.js";
import { courseApi } from "@/api/courseApi.js";
import { customerServiceApi } from "@/api/customerServiceApi.js";
import dayjs from "dayjs";

const COUNT_DOWN_SECOND = 30;

export default {
  components: {
    footerMenu,
    csWidget,
  },
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      contactImg: require("@/assets/images/aida-cs.png"),
      //contactImg: require("@/assets/images/paySucceed-kaiwei-contact.png"),
      //customserviceImg: require('@/assets/images/custom-service.png'),
      courseId: 0,
      courseName: "",
      orderId: 0,
      clock: null,
      clockCount: 0,
      showCsWidget: false,
      price: 0,
      userPhone: "",
      payTime: "",
      weChat: {
        id: 0,
        title: "",
        text: "",
        weChat: {
          wechatId: "",
          url: "",
          backupWechatId: "",
        },
      },
      jumpClock: null,
      jumpSeconds: COUNT_DOWN_SECOND,
    };
  },
  methods: {
    getId() {
      if (this.$route.query && this.$route.query.id) {
        this.orderId = this.$route.query.id;
      }
      if (this.orderId <= 0) {
        this.showCsWidget = true;
      }
      return this.orderId;
    },
    onGoBack() {
      //this.$router.go(-1);
      let url = "";
      if (process.env.NODE_ENV == "development") {
        url = "http://ckw.kaiweitalk.com/courseDetail?id=" + this.courseId;
      } else {
        url = "http://aida101.com/courseDetail?id=" + this.courseId;
      }
      if (url) {
        window.location.href = url;
      } else {
        this.$router.go(-1);
      }
    },
    onQueryPayment() {
      if (this.orderId <= 0) {
        console.log("order id <= 0", this.orderId);
        return;
      }
      orderApi
        .queryOrderPayment(this.orderId)
        .then((ret) => {
          const { code, msg, data } = ret;
          console.log("..", data);
          if (code === 0 && data) {
            this.price = data.totalFee;
            this.courseId = data.courseId;
            this.courseName = data.courseName;
            this.userPhone = data.userPhone;

            const dayTime = dayjs(Number(data.updateTime));
            this.payTime = dayTime.format("YYYY-MM-DD HH:mm");
            if (data.status === 1) {
              //todo
            } else {
              //todo
            }
          } else {
            this.showCsWidget = true;
          }
        })
        .catch((err) => {
          this.showCsWidget = true;
        });
    },
    getBanner() {
      customerServiceApi.get(1002).then((ret) => {
        if (ret.code == 0 && ret.data) {
          this.weChat = ret.data;
        }
      });
    },
    onGotoMyCourse() {
      this.$router.push({
        path: "/userCourse",
        query: {
          id: 1,
        },
      });
    },
    onCountDown() {
      this.jumpSeconds = COUNT_DOWN_SECOND;
      this.jumpClock = setInterval(() => {
        if (this.jumpSeconds <= 1) {
          clearInterval(this.jumpClock);
          this.jumpSeconds = COUNT_DOWN_SECOND;
          this.onGotoMyCourse();
        } else {
          this.jumpSeconds -= 1;
        }
      }, 1000);
    },
    listenOnCloseCsWidget() {
      this.$EventBus.$on("onCloseCsWidget", (val) => {
        this.showCsWidget = false;
      });
    },
  },
  mounted() {
    this.getBanner();
    const orderId = this.getId();
    this.orderId = orderId;
    this.onQueryPayment();
    this.listenOnCloseCsWidget();

    this.$once("hook:beforeDestory", function() {
      clearInterval(this.clock);
      this.clock = null;
      clearInterval(this.jumpClock);
      this.jumpClock = null;
    });

    this.onCountDown();
  },
  beforeDestroy() {
    clearInterval(this.clock);
    this.clock = null;
    clearInterval(this.jumpClock);
    this.jumpClock = null;
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.pay-succeed {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    height: 68px;
    // flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    margin: 0 0 12px 0;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      font-size: 20px;
      color: #323233;
      letter-spacing: 0.44px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .right {
      width: 27px;
    }
  }
  .main {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 500px;
    width: auto;
    margin: 10px 18px;
    background: #ffffff;
    border-radius: 12px;
    align-items: center;
    overflow-y: scroll;
    .phone-and-date {
      margin: 5px 0px;
      font-size: 10px;
      font-weight: 500;
      color: #323233;
      letter-spacing: 0.2px;
    }
    .title {
      margin: 10px 0px;
      font-size: 22px;
      color: #323233;
      letter-spacing: 0.49px;
      font-weight: 600;
      .price {
        font-size: 26px;
        color: #f86e00;
        letter-spacing: 0.58px;
        line-height: 30px;
        .yuan {
          font-size: 20px;
          margin: 0 8px;
        }
      }
    }
    .info-1 {
      font-weight: 500;
      font-size: 18px;
      color: #363637;
      letter-spacing: 3.92px;
      text-align: center;
      //margin: 0 0 10px 0;
    }
    .info-learn-center {
      margin: 10px 50px;
      font-weight: 500;
      font-size: 14px;
      color: #363637;
      letter-spacing: 1.92px;
      line-height: 26px;
      .span-red {
        color: #f86e00;
      }
      .next-step-or {
        margin: 15px 0px 0px 0px;
      }
      .highlight {
        color: #4d52d1;
      }
    }
    img {
      width: 210px;
      height: auto;
    }
    .info-cs {
      font-weight: 500;
      font-size: 12px;
      color: #363637;
      letter-spacing: 1.5px;
      //text-align: center;
      margin: 0px 50px;
    }
    .next-step {
      .btn {
        font-size: 18px;
        color: #ffffff;
        letter-spacing: 0.4px;
        font-weight: 500;
        background: #ff8423;
        box-shadow: 0 2px 4px 0 #ff8423;
        border-radius: 16px;
        border: none;
        font-size: 18px;
        color: #ffffff;
        letter-spacing: 0.4px;
        font-weight: 500;
        width: 150px;
        height: 42px;
        margin: 13px 17px 17px 0;
      }
    }
    // .info-2 {
    //   font-size: 16px;
    //   color: #181819;
    //   letter-spacing: 3.92px;
    //   //text-align: center;
    //   font-weight: 500;
    //   .highlight {
    //     color: #4d52d1;
    //   }
    // }
  }
  .footer {
  }
}
</style>
